import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import { Icon } from "@iconify/react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import TurnedInIcon from "@mui/icons-material/TurnedIn";

import { useState, useEffect } from "react";

import { activeLabelsState } from "./JotaiAtoms";

import { useHttpPost } from "./hooks";
import * as u from "./utility";

import { AutocompleteButton } from "./AutocompleteButton";
import { useAtomValue } from "jotai";

type LabeledStory = {
  SK: string;
  st_labels?: string[];
};

type StoryLabelsProps = {
  item?: LabeledStory;
  onLabelCreated?: (labels: string[]) => void;
  onUpdateLabels?: (storyId: string, labels: string[]) => void;
  minimized?: boolean;
  labels?: string[];
  setLabels?: (value: React.SetStateAction<string[]>) => void;
  diff?: Map<string, boolean>;
  setDiff?: (value: React.SetStateAction<Map<string, boolean>>) => void;
  disabled?: boolean;
};

export function StoryLabels({
  item,
  onLabelCreated,
  onUpdateLabels,
  minimized,
  labels,
  setLabels,
  diff,
  setDiff,
  disabled,
}: StoryLabelsProps) {
  const activeLabels = useAtomValue(activeLabelsState);
  const immutableLabels = new Set(["github", "slack", "gitlab"]);
  const [input, setInput] = useState<boolean>(false);
  const [ownLabels, setOwnLabels] = useState<string[]>([]);
  const httpPost = useHttpPost();

  const reloadLabels = (item?: LabeledStory, diff?: Map<string, boolean>) => {
    let set = setLabels || setOwnLabels;

    let newLabels = new Set<string>();
    if (diff) {
      for (const [k, v] of diff) {
        if (v) {
          newLabels.add(k);
        }
      }
    }

    if (labels) {
      for (const l of labels) {
        if (!diff || diff.get(l) !== false) {
          newLabels.add(l);
        }
      }
    }

    if (item && item.st_labels) {
      for (let l of item.st_labels) {
        if (!diff || diff.get(l) !== false) {
          newLabels.add(l);
        }
      }
    }

    let dedup = Array.from(newLabels);
    if (activeLabels) {
      dedup.sort((a, b) => {
        if (a in activeLabels && b in activeLabels) {
          return activeLabels[a].index - activeLabels[b].index;
        } else if (a in activeLabels) {
          return -1;
        } else if (b in activeLabels) {
          return 1;
        }
        return 0;
      });
    }
    set((prev) => (u.equalArrays(prev, dedup) ? prev : dedup));
  };

  useEffect(() => {
    reloadLabels(item);

    // if (setDiff) {
    //   setDiff((prev: Map<string, boolean>): Map<string, boolean> => {
    //     if (prev.size === 0) {
    //       return prev;
    //     } else {
    //       return new Map();
    //     }
    //   });
    // }
  }, [item, setLabels, setOwnLabels]);

  useEffect(() => {
    reloadLabels(item, diff);
  }, [activeLabels, setLabels, setOwnLabels]);

  const resetLabels = (labels: string[]) => {
    let set = setLabels || setOwnLabels;
    set(labels);
    if (onUpdateLabels && item) {
      onUpdateLabels(item.SK, labels);
    }
    if (diff) {
      let newDiff = new Map(diff);
      for (const k of newDiff.keys()) {
        newDiff.set(k, labels.includes(k));
      }
      for (let k of labels) {
        newDiff.set(k, true);
      }
      if (setDiff) {
        setDiff(newDiff);
      }
    }
  };

  const handleCreate = (newValue: string) => {
    let ll = labels || ownLabels;
    let set = setLabels || setOwnLabels;

    const request = async (labels: string[]) => {
      try {
        if (!item) {
          return;
        }

        const response = await httpPost("label", {
          set: {
            story: item.SK,
            labels: labels,
          },
        });
        if (response.data.labels) {
          resetLabels(response.data.labels);
        }
      } catch (err) {
      } finally {
      }
    };

    if (!newValue) {
      return;
    }

    const splitLabels = newValue.trim().split(" ");
    let validatedLabels = [];
    for (let label of splitLabels) {
      if (label && !ll.includes(label)) {
        if (activeLabels) {
          for (let [k] of Object.entries(activeLabels)) {
            if (activeLabels[k].display === label) {
              label = k;
              if (ll.includes(k)) {
                return;
              }
            }
          }
        }
        if (label.match(/^[0-9a-zA-Z:/_]+$/)) {
          validatedLabels.push(label);
        }
      }
    }

    if (validatedLabels.length) {
      if (item) {
        request(validatedLabels);
      }

      if (onLabelCreated) {
        let totallyNew = [];
        for (let l of validatedLabels) {
          if (!activeLabels || !(l in activeLabels)) {
            totallyNew.push(l);
          }
        }

        if (totallyNew.length) {
          onLabelCreated(totallyNew);
        }
      }

      let newLabels = [...ll];
      let newDiff = diff ? new Map(diff) : undefined;
      for (let label of validatedLabels) {
        if (!newLabels.includes(label)) {
          newLabels.push(label);
          if (newDiff) {
            newDiff.set(label, true);
          }
        }
      }
      if (item && onUpdateLabels) {
        onUpdateLabels(item.SK, newLabels);
      }
      set(newLabels);
      if (newDiff && setDiff) {
        setDiff(newDiff);
      }
    }
  };

  const handleDelete = (label: string, index: number) => {
    const request = async () => {
      try {
        if (item) {
          httpPost("label", { unset: { story: item.SK, labels: [label] } });
        }
      } catch (err) {
      } finally {
      }
    };

    let newLabels: string[] = labels || ownLabels;
    let set = setLabels || setOwnLabels;
    newLabels.splice(index, 1);
    set([...newLabels]);

    if (item && onUpdateLabels) {
      onUpdateLabels(item.SK, newLabels);
    }

    if (diff && setDiff) {
      let newDiff = new Map(diff);
      newDiff.set(label, false);
      setDiff(newDiff);
    }
    if (item) {
      request();
    }
  };

  const getLabelDisplayName = (label: string): string => {
    if (activeLabels && activeLabels[label]) {
      const display = activeLabels[label].display;
      if (typeof display === "string") {
        return display || label;
      }
    }

    return label;
  };

  const isIconLabel = (label: string): boolean => {
    return undefined !== getIconLabel(label);
  };

  const getIconLabel = (label: string): string | undefined => {
    if (activeLabels && activeLabels[label]) {
      const display = activeLabels[label].display;
      if (typeof display === "object") {
        return display.icon;
      }
    }

    return undefined;
  };

  const getAutocompleteOptions = () => {
    if (!activeLabels) {
      return [];
    }

    let result = Object.entries(activeLabels)
      .map(([k, v]) => k)
      .filter((e) => !e.startsWith("@"));

    result.sort((a, b) => {
      if (a in activeLabels && b in activeLabels) {
        return activeLabels[a].index - activeLabels[b].index;
      } else if (a in activeLabels) {
        return -1;
      } else if (b in activeLabels) {
        return 1;
      }
      return 0;
    });

    for (let i = 0; i < result.length; ++i) {
      const v = activeLabels[result[i]];
      if (typeof v.display === "string" && !result.includes(v.display)) {
        result[i] = v.display;
      }
    }

    return result;
  };

  return (
    <>
      {(!minimized || input) && (
        <Grid
          container
          spacing={0.1}
          direction="row-reverse"
          justifyContent="flex-end"
          width="auto"
        >
          {(labels || ownLabels).map((label: string, i: number) => (
            <Grid item key={label}>
              {!isIconLabel(label) && (
                <Chip
                  sx={{
                    "& .MuiChip-label": {
                      fontSize: 9,
                    },
                  }}
                  size="small"
                  label={getLabelDisplayName(label)}
                  disabled={disabled || immutableLabels.has(label)}
                  onClick={() => {
                    handleDelete(label, i);
                  }}
                />
              )}

              {getIconLabel(label) && (
                <IconButton
                  size="small"
                  disabled={disabled || immutableLabels.has(label)}
                  onClick={() => {
                    handleDelete(label, i);
                  }}
                >
                  <Icon icon={getIconLabel(label)!} />
                </IconButton>
              )}
            </Grid>
          ))}
        </Grid>
      )}
      {true && (
        <AutocompleteButton
          options={getAutocompleteOptions()}
          onSelected={handleCreate}
          variant="standard"
          placeholderText="Enter a new label"
          onInput={(v: boolean) => minimized && setInput(v)}
          disabled={disabled}
          onTextInput={undefined}
          sx={undefined}
          renderOption={undefined}
        >
          {!minimized && (
            <AddCircleIcon color={"disabled"} fontSize="inherit" />
          )}
          {minimized && <TurnedInIcon color={"disabled"} fontSize="inherit" />}
        </AutocompleteButton>
      )}
    </>
  );
}
