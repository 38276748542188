import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import AddBoxIcon from "@mui/icons-material/AddBox";
import AttachFileIcon from "@mui/icons-material/AttachFile";

import { useState, useEffect, memo, useCallback, useRef } from "react";

import { toast } from "react-toastify";
import * as riki from "jsriki";

import { storyFilterBoxState } from "./JotaiAtoms";

import { newStoriesState } from "./JotaiAtoms";
import { useAtomValue, useSetAtom } from "jotai";

import { FileUploadPreview } from "./FileUploadPreview";
import { StoryLabels } from "./StoryLabels";
import * as u from "./utility";
import {
  handleFilesFromPasteEvent,
  MAX_MESSAGE_SIZE,
  TextFieldWithSuggestions,
  StoryRef,
  getFilesFromDataTransfer,
  processUploadedFiles,
  SuccessfulUpload,
  cacheUploadedFiles,
} from "./SendMessageBox";

import { useHttpPostAndProject, useProjectCache, useUploadFile } from "./hooks";
import { DummyStory } from "./StoryItem";
import { FileUpload } from "./MessageItem";

type CreateErrorToastProps = {
  onRetry: (e: React.MouseEvent<HTMLElement>) => void;
};

function CreateErrorToast({ onRetry }: CreateErrorToastProps) {
  return (
    <Stack direction={"row"} alignItems={"center"}>
      <Typography fontSize={12}>Failed to create a new story</Typography>
      <Button onClick={onRetry} size="small">
        RETRY
      </Button>
    </Stack>
  );
}

const makeFileKey = (file: File) => {
  return `${file.name}:${file.size}`;
};

function findSolution(expr: string): string[] {
  const solution = riki.find_solution(expr);
  if (solution && solution.length) {
    return solution;
  }

  let labels = [];
  const operands = Array.from(new Set(riki.extract_expression_operands(expr)));

  let done = false;
  for (const op of operands) {
    if (riki.evaluate_expression(expr, op)) {
      labels.push(op);
      done = true;
      break;
    }
  }

  for (let i = 0; i < operands.length - 1 && !done; ++i) {
    for (let j = i; j < operands.length && !done; ++j) {
      const op1 = operands[i];
      const op2 = operands[j];
      if (riki.evaluate_expression(expr, `${op1} ${op2}`)) {
        labels.push(op1);
        labels.push(op2);
        done = true;
        break;
      }
    }
  }

  if (!done && riki.evaluate_expression(expr, operands.join(" "))) {
    labels.push(...operands);
  }

  return labels;
}

export function WebStory() {
  const [input, setInput] = useState<boolean>(false);
  const [text, updateCachedText, setText] = useProjectCache("WebStory", "");
  const [chips, setChips] = useState<string[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<FileUpload[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<Map<string, string>>(
    new Map()
  );
  const [dragActive, setDragActive] = useState(false);
  const [refs, setRefs] = useState<StoryRef[]>([]);

  const filters = useAtomValue(storyFilterBoxState);
  const [droppedFiles, setDroppedFiles] = useState<File[]>();
  const setNewStories = useSetAtom(newStoriesState);
  const [httpPost, project] = useHttpPostAndProject();
  const uploadToS3 = useUploadFile();
  const inputRef = useRef<HTMLInputElement>();
  const [allowCreate, setAllowCreate] = useState(false);

  const getProjectFilters = (): string[] => {
    return filters && filters[project] ? filters[project] : [];
  };

  const resetChipsBasedOnCurrentSelection = () => {
    const filters = getProjectFilters().filter((e) => !e.startsWith("@"));
    const expressions = filters.filter((e) => u.isLabelExpression(e));
    let labels = filters.filter((e) => !u.isLabelExpression(e));
    for (const expr of expressions) {
      const solution = findSolution(expr);
      labels.push(...solution);
    }

    setChips(labels);
  };

  useEffect(() => {
    if (filters) {
      resetChipsBasedOnCurrentSelection();
    }
  }, [filters]);

  useEffect(() => {
    if (inputRef?.current) {
      inputRef.current.value = text || "";
    }
    updateAllowCreate(text);
  }, [text]);

  useEffect(() => {
    const value: string = inputRef?.current?.value || "";
    updateAllowCreate(value);
  }, [selectedFiles]);

  const handleKey = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleCreate();
    } else if (e.key === "Escape") {
      cancelWebStory();
    }
  };

  const handleDescChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateAllowCreate(e.target.value);
  };

  const cancelWebStory = () => {
    setText("");
    setInput(false);

    resetChipsBasedOnCurrentSelection();

    setSelectedFiles([]);
    setUploadedFiles(new Map());
    updateCachedText("");
  };

  const handleChange = (newChips: string[]) => {
    setChips(newChips);
  };

  const handleCreate = () => {
    if (!inputRef.current) {
      return;
    }

    const desc = inputRef.current.value;

    const request = async (dummy: DummyStory) => {
      try {
        const uploaded = [];
        for (let i = 0; i < selectedFiles.length; ++i) {
          const f = selectedFiles[i];
          const key = makeFileKey(f.original);
          let uri: string | undefined = uploadedFiles.get(key);
          if (!uri) {
            uri = (await uploadToS3(f.original)) || undefined;
            if (uri) {
              cacheUploadedFiles(project, [{ uri: uri, file: f }]);
            }
          }

          if (!uri) {
            return false;
          }

          uploaded.push({
            uri: uri,
            type: f.original.type,
            size: f.original.size,
            name: f.original.name,
          });
        }

        const response = await httpPost("stories", {
          new: true,
          desc: desc,
          label: chips,
          files: uploaded,
          refs: refs,
        });

        if (response.data.new.length) {
          let story = response.data.new[0];
          story["old_dummy_message"] = dummy;
          story.internal_update = true;

          setNewStories((prev) => [...prev, story]);

          // update the story in hopes to get the autodetected st_ui from images
          if (uploaded.length > 0) {
            setTimeout(() => {
              httpPost("stories", { stories: true, story: story.SK }).then(
                (response) => {
                  let story = response.data.stories[0];
                  story["old_dummy_message"] = dummy;
                  story.internal_update = true;
                  setNewStories((prev) => [...prev, story]);
                }
              );
            }, 2000);
          }
        }

        return true;
      } catch (err) {}

      return false;
    };

    if (!desc.length && !selectedFiles.length) {
      return;
    }

    const ts = u.getCurrentTimestamp();

    const dummy: DummyStory = {
      PK: "",
      SK: ts,
      SK2: ts,
      SK3: ts,
      st_desc: desc,
      st_labels: chips,
      uploaded_files: selectedFiles,
      refs: refs,
      dummy: true,
      ts: ts,
    };

    setNewStories((prev) => [...prev, dummy]);
    cancelWebStory();

    const attemptCreate = async () => {
      setNewStories((prev) => {
        let found = prev.findIndex(
          (m) => dummy.SK === m.SK && (m as DummyStory).dummy
        );
        if (found >= 0) {
          let out = [...prev];
          out[found] = { ...dummy, error: undefined };
          return out;
        }

        return [
          ...prev,
          {
            ...dummy,
            error: null,
          },
        ];
      });

      const ok = await request(dummy);
      if (ok) {
        return;
      }

      setNewStories((prev) => {
        let found = prev.findIndex(
          (m) => dummy.SK === m.SK && (m as DummyStory).dummy
        );
        if (found >= 0) {
          let out = [...prev];
          out[found] = {
            ...dummy,
            error: undefined,
            old_dummy_message: { SK: dummy.SK },
          };
          return out;
        }

        return [
          ...prev,
          {
            ...dummy,
            old_dummy_message: { SK: dummy.SK },
            error: "create error",
          },
        ];
      });

      toast.error(<CreateErrorToast onRetry={attemptCreate} />);
    };

    attemptCreate();
  };

  const addFilesToStory = (files: FileList | File[]) => {
    const silentUpload = async (newFiles: FileUpload[]) => {
      let newUploaded = new Map(uploadedFiles);
      let successfulUploads: SuccessfulUpload[] = [];
      for (const f of newFiles) {
        // this may fail, but we do not do any error handling
        const cacheKey = await uploadToS3(f.original);
        const fileKey = makeFileKey(f.original);
        if (cacheKey) {
          newUploaded.set(fileKey, cacheKey);
          successfulUploads.push({ uri: cacheKey, file: f });
        }
      }
      setUploadedFiles(newUploaded);

      cacheUploadedFiles(project, successfulUploads);
    };

    processUploadedFiles(files, selectedFiles, setSelectedFiles, silentUpload);
  };

  const handleFileSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      addFilesToStory(e.target.files);
    }
  };

  const handleDrag = (e: React.DragEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e: React.DragEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    getFilesFromDataTransfer(e.dataTransfer, setDroppedFiles);
    setInput(true);
  };

  useEffect(() => {
    if (droppedFiles) {
      addFilesToStory(droppedFiles);
      setDroppedFiles(undefined);
    }
  }, [droppedFiles]);

  const handleDelete = useCallback(
    (index: number) => {
      setSelectedFiles((prev) => {
        let next = [...prev];
        next.splice(index, 1);
        return next;
      });
    },
    [setSelectedFiles]
  );

  const handleBlur = () => {
    if (inputRef?.current) {
      updateCachedText(inputRef.current.value);
    }
  };

  useEffect(() => {
    if (input && inputRef?.current) {
      inputRef.current.value = text;
    }
  }, [input]);

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    handleFilesFromPasteEvent(e, addFilesToStory, setRefs);
  };

  const updateAllowCreate = (inputValue: string) => {
    setAllowCreate(inputValue.length > 0 || selectedFiles.length > 0);
  };

  return (
    <Box
      sx={{
        mt: 0.3,
        backgroundColor: dragActive ? "aliceblue" : undefined,
        borderRadius: 3,
      }}
      onDragEnter={handleDrag}
      onDragLeave={handleDrag}
      onDragOver={handleDrag}
      onDrop={handleDrop}
    >
      <Stack direction="row" gap={1} alignItems={input ? "top" : "center"}>
        {!input && (
          <>
            <IconButton
              onClick={() => {
                setInput(true);
              }}
            >
              <AddBoxIcon color="success" />
            </IconButton>
            <Box onClick={() => setInput(true)}>
              <Typography fontSize={11} color="silver">
                Click here to begin a new exciting story ...
              </Typography>
            </Box>
          </>
        )}
        {input && (
          <>
            <Stack sx={{ width: "60%", mt: 2 }} direction={"column"}>
              <TextFieldWithSuggestions
                inputRef={inputRef}
                fullWidth
                multiline
                variant="standard"
                size="small"
                autoFocus
                placeholder="Tell us more ..."
                onBlur={handleBlur}
                onKeyDown={handleKey}
                onChange={handleDescChange}
                onPaste={handlePaste}
                inputProps={{ maxLength: MAX_MESSAGE_SIZE }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {true && (
                        <>
                          <input
                            type="file"
                            id={`new-web-story-file-upload`}
                            hidden
                            onChange={handleFileSelected}
                            multiple
                          />
                          <label htmlFor={`new-web-story-file-upload`}>
                            <IconButton component="span">
                              <AttachFileIcon />
                            </IconButton>
                          </label>
                        </>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
              <MemoFileUploadPreview
                files={selectedFiles.map((e) => e.preview || e.original)}
                onDelete={handleDelete}
                readOnly
              />
              <Stack direction={"row"}>
                <Button
                  disabled={!allowCreate}
                  variant="text"
                  size="small"
                  onClick={handleCreate}
                >
                  OK
                </Button>
                <Button
                  variant="text"
                  size="small"
                  onClick={() => cancelWebStory()}
                >
                  Cancel
                </Button>
              </Stack>
            </Stack>
            <Stack
              direction={"row-reverse"}
              width="100%"
              justifyContent={"flex-end"}
              sx={{ mt: 2 }}
            >
              <StoryLabels
                labels={chips}
                setLabels={setChips}
                onLabelCreated={handleChange}
              />
            </Stack>
          </>
        )}
      </Stack>
    </Box>
  );
}

const MemoFileUploadPreview = memo(FileUploadPreview);
