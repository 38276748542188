import { useState, useEffect, useRef, memo, useCallback } from "react";

import Typography from "@mui/material/Typography";

import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import GroupRemoveIcon from "@mui/icons-material/GroupRemove";
import ReplayIcon from "@mui/icons-material/Replay";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";

import { useAtom, useAtomValue, useSetAtom } from "jotai";

import {
  peopleDataState,
  AccountInfo,
  selectedAccountsState,
  reloadPeopleData,
  searchState,
  selectedCategory,
  storyFilterBoxState,
  negateLabelsState,
} from "./JotaiAtoms";

import log from "./logger";

import { useHttpPostAndProject, useHttpPost } from "./hooks";
import * as lodash from "lodash";

export function PeopleData() {
  const setData = useSetAtom(peopleDataState);
  const [httpPost, project] = useHttpPostAndProject();
  const reload = useAtomValue(reloadPeopleData);

  const getData = (project: string) => {
    const request = async () => {
      try {
        const response = await httpPost("stories", {
          users: true,
          project: project,
        });

        const groups = new Map<String, AccountInfo[]>();
        for (const acc of response.data.users.accounts) {
          if (acc.group) {
            if (!groups.has(acc.group)) {
              groups.set(acc.group, [acc]);
            } else {
              groups.set(acc.group, [...groups.get(acc.group)!, acc]);
            }
          }
        }
        const data = new Map<string, AccountInfo[]>();
        for (const acc of response.data.users.accounts) {
          const key = `${acc.app}:${acc.id}`;
          if (acc.group) {
            data.set(key, groups.get(acc.group)!);
          } else {
            data.set(key, [acc]);
          }
        }

        setData(data);
      } catch (err) {
        log.debug(err);
      }
    };
    if (project) {
      request();
    }
  };

  useEffect(() => {
    if (project) {
      getData(project);
    }
  });

  useEffect(() => {
    getData(project);
  }, [project, reload]);

  return <></>;
}

function AccountDisplay({ accounts }: { accounts: AccountInfo[] }) {
  const [selectedFlag, setSelectedFlag] = useState(false);
  const setSelected = useSetAtom(selectedAccountsState);
  const reload = useAtomValue(reloadPeopleData);

  useEffect(() => {
    setSelectedFlag(false);
  }, [reload]);

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    // when clicked on a link we want just follow the link, no selecting / unselecting
    if ((e.target as HTMLElement).nodeName === "A") {
      return;
    }

    if (selectedFlag) {
      setSelectedFlag(false);
      setSelected((prev: AccountInfo[][]) => {
        const i = prev.findIndex((e) => lodash.isEqual(e, accounts));
        if (i === -1) {
          return prev;
        } else {
          return prev.slice(0, i).concat(prev.slice(i + 1));
        }
      });
    } else {
      setSelectedFlag(true);
      setSelected((prev: AccountInfo[][]) => {
        return [...prev, accounts];
      });
    }
  };

  const getAvatar = () => {
    for (const acc of accounts) {
      if (acc.avatar) {
        return acc.avatar;
      }
    }
  };

  const getName = () => {
    let name = "";
    for (const acc of accounts) {
      if (acc.name && acc.name.length > name.length) {
        name = acc.name;
      }
    }
    return name || undefined;
  };

  const emails = () => {
    const out = new Set<string>();
    for (const acc of accounts) {
      if (acc.email) {
        out.add(acc.email);
      }
    }

    return Array.from(out);
  };

  const usernames = () => {
    const out = new Set<string>();
    for (const acc of accounts) {
      if (acc.username) {
        out.add(acc.username);
      }
    }

    return Array.from(out);
  };

  const apps = () => {
    const out: [string, string | undefined][] = [];
    for (const acc of accounts) {
      if (!out.includes([acc.app, acc.url])) {
        out.push([acc.app, acc.url]);
      }
    }

    const compact: [string, string | undefined][] = [];
    for (const [app, url] of out) {
      if (url) {
        compact.push([app, url]);
      }
    }

    for (const [app, url] of out) {
      if (!url) {
        if (!compact.find(([a]) => a === app)) {
          compact.push([app, url]);
        }
      }
    }

    return compact;
  };

  return (
    <Paper
      sx={{ p: 1, backgroundColor: selectedFlag ? "#d9e5ed" : undefined }}
      onClick={handleClick}
      style={{ cursor: "pointer" }}
      elevation={accounts.length * 2}
    >
      <Stack direction="row" gap={2} alignItems={"center"}>
        {getAvatar() && (
          <Box component="img" sx={{ height: 40 }} src={getAvatar()} />
        )}

        <Stack direction={"row"} gap={2}>
          <Stack direction={"column"}>
            {getName() && (
              <Typography fontSize={12} fontWeight={"bold"}>
                {getName()}
              </Typography>
            )}
            {usernames().map((username) => (
              <Typography fontSize={11} key={username}>
                {username}
              </Typography>
            ))}
            {emails().map((email) => (
              <Typography fontSize={10} key={email}>
                {email}
              </Typography>
            ))}

            <Typography fontSize={9} color="#656565">
              {apps().map(([app, url]) => (
                <span style={{ margin: 1 }} key={app + (url ? url : "")}>
                  {url && <Link href={url}>{app}</Link>}
                  {!url && <>{app}</>}
                </span>
              ))}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Paper>
  );
}

export function PeopleControls() {
  const [selected, setSelected] = useAtom(selectedAccountsState);
  const setReloadData = useSetAtom(reloadPeopleData);
  const [httpPost, project] = useHttpPostAndProject();
  const setSearch = useSetAtom(searchState);
  const setSelectedCategory = useSetAtom(selectedCategory);
  const setGlobalFilters = useSetAtom(storyFilterBoxState);
  const setNegate = useSetAtom(negateLabelsState);

  const handleGroupAdd = () => {
    const request = async (accounts: AccountInfo[]) => {
      await httpPost("stories", { group_accounts: true, accounts: accounts });
      setReloadData((prev) => prev + 1);
    };

    const flat: AccountInfo[] = [];
    for (const group of selected) {
      flat.push(...group);
    }
    request(flat);
    setSelected([]);
  };

  const handleGroupRemove = () => {
    const request = async (group_ids: string[]) => {
      for (const group_id of group_ids) {
        await httpPost("stories", { disband_group: true, group_id });
      }
      setReloadData((prev) => prev + 1);
    };

    const group_ids: string[] = [];
    for (const group of selected) {
      if (group.length > 1) {
        if (!group[0].group) {
          throw new Error("group is expected");
        }
        group_ids.push(group[0].group);
      }
    }
    request(group_ids);
    setSelected([]);
  };

  const handleReload = () => {
    setReloadData((prev) => prev + 1);
  };

  const handleShowUserStories = () => {
    setGlobalFilters((prev: any) => {
      if (!prev) {
        return prev;
      }

      if (!prev[project] || prev[project].length === 0) {
        return prev;
      }
      return { ...prev, project: [] };
    });

    setNegate(false);

    setTimeout(() => {
      setSelectedCategory("Stories");
      setSearch(selected);
    }, 0);
  };

  return (
    <>
      <IconButton
        color="inherit"
        onClick={handleReload}
        edge="start"
        sx={{ mr: 2 }}
      >
        <ReplayIcon />
      </IconButton>
      <IconButton
        color="inherit"
        onClick={handleGroupAdd}
        edge="start"
        disabled={selected.length < 2}
        sx={{ mr: 2 }}
      >
        <GroupAddIcon />
      </IconButton>
      <IconButton
        color="inherit"
        onClick={handleGroupRemove}
        edge="start"
        sx={{ mr: 2 }}
        disabled={!selected.find((e) => e.length > 1)}
      >
        <GroupRemoveIcon />
      </IconButton>
      <IconButton
        color="inherit"
        onClick={handleShowUserStories}
        edge="start"
        sx={{ mr: 2 }}
        disabled={selected.length === 0}
      >
        <PersonSearchIcon />
      </IconButton>
    </>
  );
}

export function People() {
  const data = useAtomValue(peopleDataState);
  const setSelected = useSetAtom(selectedAccountsState);

  useEffect(() => {
    setSelected((prev: AccountInfo[][]) => {
      if (prev.length === 0) {
        return prev;
      }
      return [];
    });
  }, [data]);

  useEffect(() => {
    setSelected((prev: AccountInfo[][]) => {
      if (prev.length === 0) {
        return prev;
      }
      return [];
    });
  }, []);

  const groupAccounts = () => {
    const out: AccountInfo[][] = [];

    const seen = new Set<string>();
    for (const user of data.values()) {
      if (user[0].group) {
        if (!seen.has(user[0].group)) {
          seen.add(user[0].group);
          out.push(user);
        }
      } else {
        out.push(user);
      }
    }
    return out;
  };

  return (
    <Grid container spacing={1.5} direction="row">
      {groupAccounts().map((group) => (
        <Grid
          item
          key={`${group[0].app || ""}-${group[0].id || ""}-${
            group[0].email || ""
          }-${group[0].username || ""}`}
        >
          <AccountDisplay accounts={group} />
        </Grid>
      ))}
    </Grid>
  );
}
