import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { Icon } from "@iconify/react";
import CancelIcon from "@mui/icons-material/Cancel";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import ErrorIcon from "@mui/icons-material/Error";
import LinearProgress from "@mui/material/LinearProgress";
import { ButtonLink } from "./Image";

import { Image, ImageProps } from "./Image";
import { Pdf } from "./Pdf";
import { TextPreview } from "./TextPreview";

import React, { useEffect, useState, useRef } from "react";
import { MAX_FILE_SIZE_PREVIEW } from "./constants";
import { AttachedFile, MessageItem } from "./MessageItem";
import { EmbeddedFile } from "./EmbeddedFile";

import { useDownloadAndOpenFile } from "./hooks";
import { imageToggleAtom } from "./JotaiAtoms";
import { useAtomValue } from "jotai";
import CropOriginalIcon from "@mui/icons-material/CropOriginal";
import JSONPretty from "react-json-pretty";
import { JSON_THEME } from "./constants";
import * as riki from "jsriki";
import { toBytes } from "fast-base64";

export type PreviewFileInfo = {
  url: string;
  name: string;
  filetype: string;
  mimetype: string;
  index: number;
  size: number;
};

export function isTextMimeType(mimetype?: string) {
  return (
    mimetype &&
    (mimetype.startsWith("text/") ||
      mimetype === "application/json" ||
      mimetype === "application/x-csh" ||
      mimetype === "application/x-sh" ||
      mimetype === "application/xml" ||
      mimetype === "application/javascript" ||
      mimetype === "application/x-javascript")
  );
}

const isImage = (f?: { type?: string }) => {
  const out = f?.type ? f.type.startsWith("image/") : false;
  return out;
};

const isPdf = (f?: { type?: string }) => {
  const out = f ? f.type === "application/pdf" : false;
  return out;
};

const isJson = (f?: { type?: string }) => {
  const out = f ? f.type === "application/json" : false;
  return out;
};

type IndexedFile = {
  fileOrUrl: File | string;
  index: number;
  name?: string;
  type?: string;
  size?: number;
  preview?: "image" | "text" | "pdf" | "json" | "diff" | "audio";
};

const getFileKey = (f: IndexedFile) => {
  if (f.fileOrUrl instanceof File) {
    return `${f.name}-${f.size}`;
  } else {
    return f.index;
  }
};

export function categorizeFilesForDisplay(
  files: (File | AttachedFile)[],
  startIndex?: number,
  usePreviews?: boolean
) {
  let newPreviews: IndexedFile[] = [];
  let newLinks: IndexedFile[] = [];

  const off: number = startIndex === undefined ? 0 : startIndex;

  files.forEach((f, i) => {
    const index = i + off;

    let fileAttrs = {
      name: f.name,
      size: f.size,
      type: f.type,
      fileOrUrl:
        f instanceof File
          ? f
          : f.uri
          ? f.uri
          : new File([f.content!], f.name || "attachment", { type: f.type }),
    };

    if (usePreviews && !(f instanceof File) && f.image_preview) {
      fileAttrs.fileOrUrl = f.image_preview;
      fileAttrs.type = "image/jpeg";
      fileAttrs.size = undefined;
    }

    if (f.size && f.size > MAX_FILE_SIZE_PREVIEW && !(f instanceof File)) {
      newLinks.push({ ...fileAttrs, index });
    } else if (isPdf(f)) {
      newPreviews.push({ ...fileAttrs, index, preview: "pdf" });
    } else if (isImage(f)) {
      // f = {
      //   uri: "s3://topstorie-user-uploads-prod2/cognito/topstorie/fc536aec-a5fd-4b71-8612-473d9c2fd30d/test-74723716-a483-478e-8d8c-04437b278fdb-123",
      //   type: "image/unknown",
      // };
      newPreviews.push({ ...fileAttrs, index, preview: "image" });
    } else if (isJson(f)) {
      newPreviews.push({ ...fileAttrs, index, preview: "json" });
    } else if (f.type === "text/x-diff") {
      newPreviews.push({ ...fileAttrs, index, preview: "diff" });
    } else if (isTextFileType(f)) {
      newPreviews.push({ ...fileAttrs, index, preview: "text" });
    } else if (f.type && f.type.startsWith("audio/")) {
      newPreviews.push({ ...fileAttrs, index, preview: "audio" });
    } else {
      newLinks.push({ ...fileAttrs, index });
    }
  });

  if (newPreviews.length + newLinks.length != files.length) {
    throw new Error();
  }

  return { previews: newPreviews, links: newLinks };
}

type FileUploadPreviewProps = {
  item?: MessageItem;
  files: (File | AttachedFile)[];
  onDelete?: (index: number) => void;
  fontSize?: number;
  readOnly?: boolean;
  markdown?: boolean;
};

export function FileUploadPreview({
  item,
  files,
  onDelete,
  fontSize,
  readOnly,
  markdown,
}: FileUploadPreviewProps) {
  const [previews, setPreviews] = useState<IndexedFile[]>();
  const [links, setLinks] = useState<IndexedFile[]>();

  useEffect(() => {
    if (files) {
      if (markdown && files.length > 1) {
        throw new Error("unsupported");
      }

      const cat = categorizeFilesForDisplay(files, undefined, true);
      // filter out files that are already displayed as part of Markdown

      // 1. collect markdown files
      const markdownFiles: string[] = [];
      if (
        item?.st_pp &&
        (item?.st_source === "github" || item?.st_source === "gitlab") &&
        typeof item.st_pp === "string" &&
        !markdown
      ) {
        const pp = JSON.parse(item.st_pp);
        for (const { t, v } of pp) {
          if (t === "img" || t === "fx") {
            const uri = v.split("|")[0];
            markdownFiles.push(uri);
          }
        }
      }

      // 2. filter out markdown files
      for (const uri of markdownFiles) {
        cat.previews = cat.previews.filter((e) => e.fileOrUrl !== uri);
        cat.links = cat.links.filter((e) => e.fileOrUrl !== uri);
      }

      // correct indecies to match those in st_files
      // again needed this for files embeded directly in markdown, like github's or gitlab's
      if (item?.st_files) {
        if (onDelete) {
          throw new Error(
            "unsupported! onDelete will require a different kind of index"
          );
        }

        const mapping = new Map<string, number>();
        item.st_files.forEach((f, i) => {
          if (f.uri) {
            mapping.set(f.uri, i);
          }
        });

        let fakeIndex = 67890;

        const correctIndecies = (linksOrPreviews: IndexedFile[]) => {
          linksOrPreviews.forEach((f, i) => {
            const url =
              typeof f.fileOrUrl === "string" ? f.fileOrUrl : undefined;
            const corrected = url ? mapping.get(url) : undefined;
            if (corrected !== undefined) {
              f.index = corrected;

              // in markdown there is only name and uri, we don't really know content type and image size
              // we'll try carrying over those attribute from st_files
              if (!(f instanceof File)) {
                const orig = item.st_files![corrected];
                if (orig.name) {
                  f.name = orig.name;
                }
                if (orig.size) {
                  f.size = orig.size;
                }
                if (orig.type) {
                  f.type = orig.type;
                }
              }
            } else if (f.fileOrUrl instanceof File) {
              // this is a dummy preview, but it should use the correct index
              if (files.length !== item.st_files?.length) {
                throw new Error("Should not get here");
              }
              f.index = i;
            } else {
              f.index = fakeIndex;
              fakeIndex += 1;
            }
          });
        };

        correctIndecies(cat.previews);
        correctIndecies(cat.links);
      }

      setPreviews(cat.previews.length ? cat.previews : undefined);
      setLinks(cat.links.length > 0 ? cat.links : undefined);
    } else {
      setPreviews(undefined);
      setLinks(undefined);
    }
  }, [files, markdown, item]);

  const getStatus = () => {
    if (item && item.dummy) {
      return item.error ? "error" : "progress";
    }
    return undefined;
  };

  const handleError = (f: IndexedFile) => {
    setPreviews((prev) => {
      if (!prev) {
        return prev;
      }

      const found = prev.findIndex((e) => e.index === f.index);
      if (found >= 0) {
        if (prev.length === 1) {
          return undefined;
        }
        return prev.slice(0, found).concat(prev.slice(found + 1));
      }

      return prev;
    });

    setLinks((prev) => {
      if (!prev) {
        return [f];
      }

      const found = prev.findIndex((e) => e.index === f.index);
      if (found < 0) {
        return [...prev, f];
      }
      return prev;
    });
  };

  return (
    <>
      <Grid
        container
        direction={"row"}
        justifyItems={"flex-start"}
        alignItems={"flex-start"}
        gap={0.3}
      >
        {previews &&
          previews.map((f: IndexedFile, i) => (
            <Grid item key={`preview:${getFileKey(f)}`}>
              <DeleteOverlay
                showStatus={getStatus()}
                onDelete={onDelete ? () => onDelete(f.index) : undefined}
              >
                <SingleFilePreview
                  fileObject={
                    f.fileOrUrl instanceof File ? f.fileOrUrl : undefined
                  }
                  fileAttachment={
                    !(f.fileOrUrl instanceof File)
                      ? { ...f, uri: f.fileOrUrl }
                      : undefined
                  }
                  fontSize={fontSize}
                  index={f.index}
                  readOnly={readOnly || item?.dummy}
                  item={item}
                  previewType={f.preview!}
                  onError={() => handleError(f)}
                />
              </DeleteOverlay>
            </Grid>
          ))}
      </Grid>

      {links && (
        <Stack>
          {links.map((f) => (
            <DeleteOverlay
              showStatus={getStatus()}
              onDelete={onDelete ? () => onDelete(f.index) : undefined}
              key={`link:${getFileKey(f)}`}
            >
              <DownloadLink file={f} />
            </DeleteOverlay>
          ))}
        </Stack>
      )}
    </>
  );
}

function DownloadLink({
  item,
  file,
  readOnly,
}: {
  item?: MessageItem;
  file: IndexedFile;
  readOnly?: boolean;
}) {
  const openNewTab = useDownloadAndOpenFile();

  return (
    <Stack direction="row" alignItems={"center"}>
      <Icon icon="uim:paperclip" style={{ paddingRight: 1 }} />
      <ButtonLink
        text={file.name || "attachment"}
        onclick={() => {
          if (file.fileOrUrl) {
            openNewTab(
              file.name || `attachment-${file.index}`,
              file.fileOrUrl,
              item
            );
          }
        }}
        disabled={readOnly || !file.fileOrUrl ? true : false}
      />
      <Box width={30}></Box>
    </Stack>
  );
}

type TextFilePreviewProps = {
  data?: File | string | Uint8Array;
  onError?: () => void;
  name?: string;
  mimetype?: string;
  fontSize?: number;
  index?: number;
  fileName?: string;
  readOnly?: boolean;
  item?: MessageItem;
  children?: (textData: string) => React.ReactNode;
};

function TextFilePreview({
  data,
  onError,
  fileName,
  mimetype,
  fontSize,
  index,
  readOnly,
  item,
  children,
}: TextFilePreviewProps) {
  const [contents, setContents] = useState<string | ArrayBuffer | Uint8Array>();
  useEffect(() => {
    if (data instanceof File) {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.result) {
          setContents(reader.result);
        }
      };
      reader.readAsArrayBuffer(data);
    } else if (typeof data === "string") {
      setContents(data);
    } else if (data instanceof Uint8Array) {
      setContents(data);
    }
  }, [data]);

  return (
    <>
      {contents && (
        <TextPreview
          data={contents}
          disableControls={readOnly}
          fontSize={fontSize}
          onError={() => onError!()}
          fileName={fileName || (data instanceof File ? data.name : undefined)}
          mimetype={mimetype || (data instanceof File ? data.type : undefined)}
          index={index}
          item={item}
          children={children}
        />
      )}
    </>
  );
}

export const isTextFileType = (f: { type?: string }): boolean => {
  if (isTextMimeType(f.type)) {
    return true;
  }
  return false;
};

type RemoteFile = {
  uri: string;
  name?: string;
  size?: number;
  type?: string;
};

function ImageWithToggle(props: ImageProps) {
  const toggle = useAtomValue(imageToggleAtom);
  if (!toggle) {
    return <CropOriginalIcon color="disabled" />;
  }

  return <Image {...props} />;
}

type SingleFilePreviewProps = {
  item?: MessageItem;
  fileObject?: File;
  fileAttachment?: RemoteFile;
  readOnly?: boolean;
  fontSize?: number;
  index?: number;
  previewType: "pdf" | "text" | "image" | "json" | "diff" | "audio";
  onError?: () => void;
};

function SingleFilePreview({
  item,
  fileObject,
  fileAttachment,
  readOnly,
  fontSize,
  index,
  previewType,
  onError,
}: SingleFilePreviewProps) {
  return (
    <>
      {true && (
        <>
          {previewType == "image" && (
            <ImageWithToggle
              image={
                fileAttachment
                  ? {
                      name: fileAttachment.name,
                      url: fileAttachment.uri,
                      mimetype: fileAttachment.type,
                    }
                  : undefined
              }
              fileObject={fileObject}
              item={item}
              index={index}
              fontSize={fontSize}
              readOnly={readOnly}
              onError={onError}
            />
          )}
          {previewType == "pdf" && (
            <EmbeddedFile
              fileDescriptor={
                fileAttachment
                  ? {
                      name: fileAttachment?.name,
                      url: fileAttachment?.uri!,
                      mimetype: fileAttachment?.type || "application/pdf",
                    }
                  : undefined
              }
              fileObject={fileObject}
              filetypes={new Set(["pdf"])}
              item={item}
              index={index}
              encoding="base64"
              readOnly={readOnly}
              onError={onError}
            >
              <Pdf fontSize={fontSize} />
            </EmbeddedFile>
          )}

          {previewType == "text" && (
            <EmbeddedFile
              fileDescriptor={
                fileAttachment
                  ? {
                      name: fileAttachment?.name,
                      url: fileAttachment?.uri!,
                      mimetype: fileAttachment?.type || "text/plain",
                    }
                  : undefined
              }
              fileObject={fileObject}
              item={item}
              index={index}
              readOnly={readOnly}
              onError={onError}
            >
              <TextFilePreview fontSize={fontSize} />
            </EmbeddedFile>
          )}
          {previewType == "json" && (
            <EmbeddedFile
              fileDescriptor={
                fileAttachment
                  ? {
                      name: fileAttachment?.name,
                      url: fileAttachment?.uri!,
                      mimetype: fileAttachment?.type || "application/json",
                    }
                  : undefined
              }
              fileObject={fileObject}
              item={item}
              index={index}
              readOnly={readOnly}
              onError={onError}
            >
              <TextFilePreview fontSize={fontSize}>
                {(textData: string) => (
                  <JSONPretty data={textData} theme={JSON_THEME} />
                )}
              </TextFilePreview>
            </EmbeddedFile>
          )}
          {previewType == "diff" && (
            <EmbeddedFile
              fileDescriptor={
                fileAttachment
                  ? {
                      name: fileAttachment?.name,
                      url: fileAttachment?.uri!,
                      mimetype: fileAttachment?.type || "text/x-diff",
                    }
                  : undefined
              }
              fileObject={fileObject}
              item={item}
              index={index}
              readOnly={readOnly}
              onError={onError}
            >
              <TextFilePreview fontSize={fontSize}>
                {(textData: string) => <UnidiffRust data={textData} />}
              </TextFilePreview>
            </EmbeddedFile>
          )}
          {previewType == "audio" && (
            <EmbeddedFile
              fileDescriptor={
                fileAttachment
                  ? {
                      name: fileAttachment?.name,
                      url: fileAttachment?.uri!,
                      mimetype: fileAttachment?.type || "audio/unknown",
                    }
                  : undefined
              }
              fileObject={fileObject}
              item={item}
              index={index}
              readOnly={readOnly}
              onError={onError}
            >
              <DownloadLink
                item={item}
                file={{
                  index: index || 0,
                  fileOrUrl: fileObject || fileAttachment!.uri,
                  preview: "audio",
                  name: fileObject?.name || fileAttachment?.name,
                  size: fileObject?.size || fileAttachment?.size,
                  type: fileObject?.type || fileAttachment?.type,
                }}
              />
              <AudioPlayback fontSize={fontSize} />
            </EmbeddedFile>
          )}
        </>
      )}
    </>
  );
}

const COLOR_DIFF_ADD = "#2dfc50";
const COLOR_DIFF_DEL = "#ff3030";

type DiffLine = {
  line: string;
  color?: string;
};

const parseUnidiff = (data: string): DiffLine[] => {
  let out: DiffLine[] = data.split("\n").map((e) => ({
    line: e,
  }));

  for (let i = 3; i < out.length; ++i) {
    const cur = out[i];
    if (cur.line.length > 0) {
      if (cur.line[0] === "+") {
        cur.color = COLOR_DIFF_ADD;
      } else if (cur.line[0] === "-") {
        cur.color = COLOR_DIFF_DEL;
      } else if (cur.line[0] === "@") {
        out[i - 1].color = undefined;
        out[i - 2].color = undefined;
      }
    }
  }

  return out;
};

type UnidiffProps = {
  data: string;
};

export function UnidiffJs({ data }: UnidiffProps) {
  const [lines, setLines] = useState<DiffLine[]>(parseUnidiff(data));
  const ref = useRef(data);

  useEffect(() => {
    if (ref.current != data) {
      ref.current = data;
      setLines(parseUnidiff(data));
    }
  }, [data]);

  return (
    <>
      {lines.map((line: DiffLine, i: number) => (
        <div key={i} style={{ color: line.color }}>
          {line.line}
        </div>
      ))}
    </>
  );
}

export function UnidiffRust({ data }: UnidiffProps) {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: riki.colorize_diff(data, COLOR_DIFF_ADD, COLOR_DIFF_DEL),
      }}
    />
  );
}

type DeleteOverlayProps = {
  onDelete?: () => void;
  children: React.ReactNode;
  showStatus?: string;
};

function DeleteOverlay({ onDelete, showStatus, children }: DeleteOverlayProps) {
  const [hover, setHover] = useState(false);

  const handleDelete = () => {
    if (onDelete) {
      onDelete();
    }
  };

  return (
    <Box
      component="span"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Box sx={{ position: "relative", display: "inline-block" }}>
        {onDelete && hover && (
          <Box sx={{ position: "absolute", right: 0, zIndex: 5 }}>
            <IconButton onClick={handleDelete} size="small">
              <CancelIcon
                fontSize="inherit"
                sx={{ borderRadius: "50%", background: "white" }}
              />
            </IconButton>
          </Box>
        )}
        {showStatus === "error" && (
          <Box
            sx={{
              position: "absolute",
              left: 0,
              height: "100%",
              width: "100%",
              zIndex: 5,
            }}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <ErrorIcon sx={{ color: "red" }} />
          </Box>
        )}
        {showStatus === "progress" && (
          <Box
            sx={{
              position: "absolute",
              left: 0,
              height: "100%",
              width: "100%",
              zIndex: 5,
            }}
            display="flex"
            justifyContent="center"
            alignItems="flex-start"
          >
            <Box sx={{ width: "97%", m: 0.1 }}>
              <LinearProgress
                color="secondary"
                sx={{
                  backgroundColor: "lightgrey",
                  "& .MuiLinearProgress-bar": {
                    backgroundColor: "grey",
                  },
                }}
              />
            </Box>
          </Box>
        )}
        <>{children}</>
      </Box>
    </Box>
  );
}

type AudioPlaybackProps = {
  data?: string | File | Uint8Array;
  item?: MessageItem;
  onError?: (error?: Error) => void;
  fontSize?: number;
  readOnly?: boolean;
  index?: number;
  fileName?: string;
  mimetype?: string;
};

export function AudioPlayback({
  data,
  item,
  onError,
  fontSize,
  readOnly,
  index,
  mimetype,
  fileName,
}: AudioPlaybackProps) {
  const [audioData, setAudioData] = useState<File>();

  useEffect(() => {
    if (typeof data === "string") {
      toBytes(data).then((arr: Uint8Array) => {
        const f = new File([arr.buffer], fileName || "audio", {
          type: mimetype || "audio/unknown",
        });
        setAudioData(f);
      });
    } else if (data instanceof File) {
      return setAudioData(data);
    } else if (data instanceof Uint8Array) {
      const f = new File([data.buffer], fileName || "audio", {
        type: mimetype || "audio/unknown",
      });
      setAudioData(f);
    }
  }, [data]);

  return (
    <>
      {audioData && (
        <Box width={400} fontSize={10}>
          <audio src={URL.createObjectURL(audioData)} controls />
        </Box>
      )}
    </>
  );
}
